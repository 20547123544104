<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel title="DAFTAR TARIF RETRIBUSI" class="panel panel-success">
            
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button class="pull-right" variant="primary" @click="create">
                                    <i class="fa fa-plus pr-1"></i> Tambah Tarif Retribusi
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2" v-role:any="'Administrator|Maintenance'">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-0"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="filter_InputPerangkat(perangkatpd)"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->
                        
                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
            >
                <!-- 
                lebar kolom
                -------------------------------------------------- -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: 
                                field.key === 'aktif' ? '65px' : 
                                field.key === 'actions' ? '105px' : ''
                        }"
                    />
                </template>
                <!-- 
                nomor baris
                -------------------------------------------------- -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- 
                isi baris
                -------------------------------------------------- -->
                <template #cell(ret_jenis_1)="data">
                    <div v-if="data.item.ret_jenis_3">
                        <div v-if="data.item.ret_jenis_3.ret_jenis_2">
                            <div v-if="data.item.ret_jenis_3.ret_jenis_2.ret_jenis_1">
                                ID : <strong>{{ data.item.ret_jenis_3.ret_jenis_2.ret_jenis_1.id }}</strong>
                                <br><div v-html="data.item.ret_jenis_3.ret_jenis_2.ret_jenis_1.nama"></div>
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(ret_jenis_2)="data">
                    <div v-if="data.item.ret_jenis_3">
                        <div v-if="data.item.ret_jenis_3.ret_jenis_2">
                            ID : <strong>{{ data.item.ret_jenis_3.ret_jenis_2.id }}</strong>
                            <br><div v-html="data.item.ret_jenis_3.ret_jenis_2.nama"></div>
                        </div>
                    </div>
                </template>
                <template #cell(ret_jenis_3)="data">
                    <div v-if="data.item.ret_jenis_3">
                        ID : <strong>{{ data.item.ret_jenis_3.id }}</strong>
                        <br><div v-html="data.item.ret_jenis_3.nama"></div>
                    </div>
                </template>
                <template #cell(id)="data">
                    {{ data.item.id }}
                </template>
                <template #cell(parameter)="data">
                    <div v-if="data.item.parameter_1" v-html="data.item.parameter_1"></div>
                    <div v-if="data.item.parameter_2" v-html="data.item.parameter_2"></div>
                    <div v-if="data.item.parameter_3" v-html="data.item.parameter_3"></div>
                    <div v-if="data.item.parameter_4" v-html="data.item.parameter_4"></div>
                </template>
                <template #cell(tarif)="data">
                    {{ data.item.tarif.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                </template>
                <template #cell(kode_dh)="data">
                    {{ data.item.kode_dh }}
                    <br>{{ data.item.produk_hukum.judul }}
                    <!-- <div :class="`label label-success`"
                        :id="`tooltip-${data.item.id}`">
                        {{ data.item.kode_dh }}
                    </div>
                    <b-tooltip
                        :target="`tooltip-${data.item.id}`"
                        variant="secondary"
                        placement="left"
                        triggers="hover"
                    >
                        {{ data.item.produk_hukum.judul }}
                    </b-tooltip> -->
                </template>
                <template #cell(aktif)="data">
                    <div v-if="data.item.aktif" class="label label-success">Aktif</div>
                    <div v-else class="label label-danger">Non Aktif</div>
                </template>
                <!-- 
                button actions 
                -------------------------------------------------- -->
                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="view(data.item.id)"
                            ><i class="fa fa-eye"></i>
                            Lihat</b-dropdown-item
                        >
                        <b-dropdown-item @click="edit(data.item.id)"
                            ><i class="fa fa-edit"></i>
                            Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="
                                swalNotification('error', data.item.id)
                            "
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
                <!-- end BAGIAN ACTIONS -->
            </b-table>
            <div class="divider"></div>

            <!-- BAGIAN PAGINATION -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group
                        :label-cols="2"
                        label="Per page"
                        class="mb-0"
                    >
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end BAGIAN PAGINATION -->
        </panel>
        <!-- Panel end here -->

        <!-- 
        Modal untuk Form view()
        -------------------------------------------------- -->
        <b-modal
            title="Detail Klasifikasi"
            v-model="showModalView"
            id="modal-view"
            hide-footer
            no-close-on-backdrop
        >
            <form>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Jenis Retribusi 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :options="Retribusi.ret_jenis_3"
                            :reduce="nama => nama.id"
                            v-model="form.ret_jenis_3_id"
                            placeholder="--- Pilih data ---"
                            disabled
                        >
                        </v-select>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('kode_dh')
                            }"
                            v-model="form.kode_dh"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 1
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_2"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 2
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_2"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_3"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 4
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <input
                            type="text"
                            :class="{
                                'form-control': true
                            }"
                            v-model="form.parameter_4"
                            disabled
                        />
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('tarif')
                                    }"
                                    v-model="form.tarif"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif Satuan </label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'tarif_st'
                                        )
                                    }"
                                    v-model="form.tarif_st"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Status Aktif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="aktifOptions"
                                    :reduce="nama => nama.id"
                                    v-model="form.aktif"
                                    placeholder="--- Pilih data ---"
                                    disabled
                                ></v-select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <!-- 
        Modal untuk Form create() & edit()
        -------------------------------------------------- -->
        <b-modal
            :title="
                editMode
                    ? 'Edit Data Jenis Retribusi 1'
                    : 'Tambah Data Jenis Retribusi 1'
            "
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <form
                @submit.prevent="
                    editMode ? update(detailRetTarifMixin.id) : store()
                "
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Jenis Retribusi 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="nama"
                            :get-option-label="(option) => `${option.id} ${option.nama}`"
                            :options="Retribusi.ret_jenis_3"
                            :reduce="nama => nama.id"
                            v-model="form.ret_jenis_3_id"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ id, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                            </template>
                            <template #selected-option="{ id, nama }">
                                <span>
                                    <strong>ID : {{ id }}</strong>
                                    <br><div v-html="nama"></div>
                                </span>
                                <!-- <div style="display: flex; align-items: baseline">
                                    <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                    <em style="margin-left: 0.5rem"
                                    >{{ judul }}</em
                                    >
                                </div> -->
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('ret_jenis_3_id')"
                            class="form-text text-danger"
                            >{{ errors.ret_jenis_3_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Kode
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('kode_dh')
                            }"
                            v-model="form.kode_dh"
                            placeholder="Masukkan Kode"
                        />
                        <em
                            v-if="form.errors.has('kode_dh')"
                            class="form-text text-danger"
                            >{{ errors.kode_dh[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Produk Hukum
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <v-select
                            label="judul"
                            :options="options.produk_hukum"
                            :reduce="(option) => option.id"
                            v-model="form.produk_hukum_id"
                            placeholder="--- Pilih data ---"
                        >
                            <template #option="{ kode, nomor, tahun, judul }">
                                <span>
                                    <strong>Kode : {{ kode }}</strong>
                                    <br><strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                    <br>Tentang {{ judul }}
                                </span>
                            </template>
                            <template #selected-option="{ kode, nomor, tahun, judul }">
                                <span>
                                    <strong>Kode : {{ kode }}</strong>
                                    <br><strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                    <br>Tentang {{ judul }}
                                </span>
                                <!-- <div style="display: flex; align-items: baseline">
                                    <strong>Nomor {{ nomor }} Tahun {{ tahun }}</strong>
                                    <em style="margin-left: 0.5rem"
                                    >Tentang {{ judul }}</em
                                    >
                                </div> -->
                            </template>
                        </v-select>
                        <em
                            v-if="form.errors.has('produk_hukum_id')"
                            class="form-text text-danger"
                            >{{ errors.produk_hukum_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 1
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <ckeditor
                            :config="editorConfig"
                            v-model="form.parameter_1"
                        ></ckeditor>
                        <em
                            v-if="form.errors.has('parameter_1')"
                            class="form-text text-danger"
                            >{{ errors.parameter_1[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 2
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <ckeditor
                            :config="editorConfig"
                            v-model="form.parameter_2"
                        ></ckeditor>
                        <em
                            v-if="form.errors.has('parameter_2')"
                            class="form-text text-danger"
                            >{{ errors.parameter_2[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 3
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <ckeditor
                            :config="editorConfig"
                            v-model="form.parameter_3"
                        ></ckeditor>
                        <em
                            v-if="form.errors.has('parameter_3')"
                            class="form-text text-danger"
                            >{{ errors.parameter_3[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Parameter 4
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                        <ckeditor
                            :config="editorConfig"
                            v-model="form.parameter_4"
                        ></ckeditor>
                        <em
                            v-if="form.errors.has('parameter_4')"
                            class="form-text text-danger"
                            >{{ errors.parameter_4[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('tarif')
                                    }"
                                    v-model="form.tarif"
                                    placeholder="Masukkan Tarif"
                                />
                                <em
                                    v-if="form.errors.has('tarif')"
                                    class="form-text text-danger"
                                    >{{ errors.tarif[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Tarif Satuan <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-6">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'tarif_st'
                                        )
                                    }"
                                    v-model="form.tarif_st"
                                    placeholder="Masukkan Tarif Satuan"
                                />
                                <em
                                    v-if="form.errors.has('tarif_st')"
                                    class="form-text text-danger"
                                    >{{ errors.tarif_st[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Status Aktif <span class="text-danger">*</span></label
                    >
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <v-select
                                    label="nama"
                                    :options="aktifOptions"
                                    :reduce="nama => nama.id"
                                    v-model="form.aktif"
                                    placeholder="--- Pilih data ---"
                                ></v-select>
                                <em
                                    v-if="form.errors.has('aktif')"
                                    class="form-text text-danger"
                                    >{{ errors.aktif[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" type="submit">
                        <span v-if="isLoading">
                            <b-spinner
                                variant="primary"
                                label="Spinning"
                                small
                            ></b-spinner>
                        </span>
                        <i class="fa fa-save" v-else></i>
                        Simpan
                    </b-button>
                </span>
                <span class="float-right">
                    <b-button variant="outline-secondary" @click="close"
                        ><i class="fa fa-times"></i> Tutup</b-button
                    >
                </span>
            </form>
        </b-modal>
        <!-- end Modal -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";
import dataRetribusi from "@/helper/api/retribusi_jenis.js";

export default {
    mixins: [hapusToken, dataRetribusi],
    components: {
        HeadTitle
    },
    data() {
        return {
            selected: "1",
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            role: JSON.parse(localStorage.getItem("roles"))[0],

            /**
             * BAGIAN FILTER
             * --------------------------------------------------
             */
            perangkatpd: "all",

            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right"
                },
                {
                    key: "ret_jenis_1",
                    label: "JENIS RETRIBUSI 1",
                    sortable: true
                },
                {
                    key: "ret_jenis_2",
                    label: "JENIS RETRIBUSI 2",
                    sortable: true
                },
                {
                    key: "ret_jenis_3",
                    label: "JENIS RETRIBUSI 3",
                    sortable: true
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true,
                    tdClass: "text-right"
                },
                {
                    key: "parameter",
                    label: "PARAMETER",
                    sortable: true
                },
                {
                    key: "tarif",
                    label: "TARIF",
                    sortable: true,
                    tdClass: 'text-right'
                },
                {
                    key: "kode_dh",
                    label: "DASAR HUKUM",
                    sortable: true
                },
                {
                    key: "aktif",
                    label: "STATUS",
                    sortable: true
                },
                {
                    key: "actions",
                    label: "Opsi",
                    sortable: false
                }
            ],
            status_id: null,
            editMode: false,
            showModalForm: false,
            showModalView: false,
            form: new Form({
                ret_jenis_3_id: "",
                kode_dh: "",
                produk_hukum_id: "",
                parameter_1: "",
                parameter_2: "",
                parameter_3: "",
                parameter_4: "",
                tarif: "",
                tarif_st: "",
                aktif: ""
            }),
            // options ...
            options: {
                produk_hukum: []
            },
            errors: [],
            isLoading: false,
            editorConfig: {
                removeButtons:
                    "Image,Table,Cut,Copy,RemoveFormat,Anchor,Source,Styles,Format,About,Blockquote"
            },
            aktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktif"
                },
                {
                    id: 1,
                    nama: "Aktif"
                }
            ]
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        },
        ...mapState(["PemerintahDaerah", "filterTest", "RefRek", "Perangkat", "Retribusi"])
    },
    mounted() {
        this.$store.dispatch("Retribusi/getRetJenis3");
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/retribusi/ret-tarif", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    perangkatpd: this.perangkatpd,
                    retjenis1: true,
                    retjenis2: true,
                    retjenis3: true,
                    rettarif: true
                }
            });
            return promise
                .then(response => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch(error => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default"
            }).then(result => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/retribusi/ret-tarif/" + id)
                        .then(response => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000
                            }).catch(error => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            // this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm = true;
            this.getoptionProdukHukum()
        },
        // show modal
        edit(id) {
            this.form.reset();
            this.form.clear();
            this.editMode = true;
            // this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm = true;
            // detailRetribusi mixin
            this.detailRetTarif(id);
            this.getoptionProdukHukum()
        },
        view(id) {
            this.editMode = true;
            // this.$root.$emit("bv::show::modal", "modal-view");
            this.showModalView = true;
            // detailRetribusi mixin
            this.detailRetTarif(id);
            this.getoptionProdukHukum()
        },
        close() {
            this.form.reset();
            // this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm = false;
        },
        // store data provinsi
        store() {
            this.isLoading = true;
            this.form.parameter_1   = this.form.parameter_1.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_2   = this.form.parameter_2.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_3   = this.form.parameter_3.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_4   = this.form.parameter_4.replace("<p>", "<p class='mb-0'>")
            this.form
                .post("/api/retribusi/ret-tarif")
                .then(response => {
                    this.isLoading = false;
                    
                    // this.form.ret_jenis_3_id= ""
                    // this.form.kode_dh       = ""
                    this.form.parameter_1   = ""
                    this.form.parameter_2   = ""
                    this.form.parameter_3   = ""
                    this.form.parameter_4   = ""
                    this.form.tarif         = ""
                    // this.form.tarif_st      = ""
                    // this.form.aktif         = ""
                    // this.form.reset();

                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                        timer: 5000
                    });
                    this.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken mixin
                        this.clearAll();
                    }
                });
        },
        // store data provinsi
        update(id) {
            this.isLoading = true;
            
            this.form.parameter_1   = this.form.parameter_1.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_2   = this.form.parameter_2.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_3   = this.form.parameter_3.replace("<p>", "<p class='mb-0'>")
            this.form.parameter_4   = this.form.parameter_4.replace("<p>", "<p class='mb-0'>")

            this.form
                .put("/api/retribusi/ret-tarif/" + id)
                .then(response => {
                    this.isLoading = false;
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false
                    });
                    this.reload();
                })
                .catch(error => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                });
        },

        getoptionProdukHukum(){
            axios.get('/api/data-induk/jdih/produk-hukum/getoption')
            .then( response => {
                this.options.produk_hukum = response.data.data;
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapus token mixins
                    this.clearAll();
                }else{
                    console.log(error)
                }
            })
        },

        /**
         * BAGIAN FILTER
         * --------------------------------------------------
         */
        filter_InputPerangkat(value) {
            this.perangkatpd = value;
            this.reload();
        },
    }
};
</script>

<style scoped>

</style>
